import { MathRender } from "@modules/gameplay/components/base";
import React from "react";
import parse from "html-react-parser";
import style from "style-to-object";
import ReactPlayer from "react-player";

const removeInlineStyling = [
    "p",
    // "div",
    // "span",
    "sup",
    "sub",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
];

const TextHtml = ({ html }) => {
    const { domToReact } = parse;

    return (
        <div className="htmlQuestionContainer">
            {parse(html, {
                replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.style) {
                        try {
                            style(domNode.attribs.style);
                            if (
                                domNode.name &&
                                removeInlineStyling.includes(domNode.name)
                            ) {
                                // delete the attribute who's inline styling is not required
                                // then convert the dom node to react
                                delete domNode.attribs.style;
                                return domToReact(domNode);
                            }
                        } catch (error) {
                            // delete the attribute that's causing the error
                            // then convert the dom node to react
                            delete domNode.attribs.style;
                            return domToReact(domNode);
                        }
                    }
                },
            })}
        </div>
    );
};

function isHTML(input: string) {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(
        input,
    );
}

const RenderPart = ({ part,handleUpdateDescriptionAssetsLoaded, textStyle = {} }) => {
    return part ? (
        part.type === "parts" ? (
            <div>
                {part.val.map((p, i) => (
                    <RenderPart
                        key={`p_p_${i}`}
                        part={p}
                        textStyle={textStyle}
                        handleUpdateDescriptionAssetsLoaded={handleUpdateDescriptionAssetsLoaded}
                    />
                ))}
            </div>
        ) : part.type === "img" ? (
            <div style={{ maxWidth: "55vw" }}>
                <img
                    // className="overflow-x question-img full-width"
                    style={{ width: "100%" }}
                    alt="Question Image"
                    src={part.val}
                    onLoad={() => {console.log('image-loaded', part.val, textStyle, handleUpdateDescriptionAssetsLoaded); handleUpdateDescriptionAssetsLoaded && handleUpdateDescriptionAssetsLoaded(part.val)}}
                />
            </div>
        ) : part.type === 'video'? (
            <div className="mt-[10px] mb-[10px] w-full">
                <ReactPlayer  url={part.val} width={'100%'}
                controls={true}
                loop={true}
                playsinline={true}
                muted={true}
                autoplay={true}
                onReady={() => {console.log('video-loaded', part.val); handleUpdateDescriptionAssetsLoaded &&  handleUpdateDescriptionAssetsLoaded(part.val)}}
                />

            </div>
        ) : isHTML(part.val) ? (
            <TextHtml html={part.val} />
        ) : part.type === "latex_div" ? (
            <div className="overflow-x" style={textStyle}>
                <MathRender src={part.val} />
            </div>
        ) : part.type === "break" ? (
            <br />
        ) : part.type === "latex_span" ? (
            <span className="overflow-x" style={textStyle}>
                <MathRender src={part.val} />
            </span>
        ) : part.type === "text" && part.val == "" ? (
            <br />
        ) : (
            <div className="full-width overflow-wrap-break" style={textStyle}>
                {part.val}
            </div>
        )
    ) : null;
};

export default RenderPart;
