import RenderPart from "./RenderPart";

const parseCMSJson = (obj: any) => {
    if (typeof obj === "string") {
        return obj;
    }
    const returnObj = obj
        ?.map(function (o: any) {
            if (o.type === "p") {
                const elem = o.children
                    .map(function (o: any) {
                        let styleArr = "";
                        if (o.fontSize) {
                            styleArr += `fontSize: ${o.fontSize}; `;
                        }
                        if (o.fontWeight) {
                            styleArr += `fontWeight: ${o.fontWeight}; `;
                        }
                        if (o.color) {
                            styleArr += `color: ${o.color}; `;
                        }
                        if (o.filter) {
                            styleArr += `filter: ${o.filter}; `;
                        }
                        const style = styleArr ? ` style="${styleArr}"` : "";

                        if (o.bold) return `<b${style}>${o.text}</b>`;
                        else if (o.italic) return `<i${style}>${o.text}</i>`;
                        else if (o.underline) return `<u${style}>${o.text}</u>`;
                        else if (o.type === "mention")
                            return o.value && o.value.length > 0
                                ? `@@@@{{${o.value}}}@@@@`
                                : "";

                        return style
                            ? `<span${style}>${o.text}</span>`
                            : o.text;
                    })
                    .join(" ");

                const lineHeight = o.lineHeight;
                const indent = o.indent;
                let styleArr = "";
                if (indent) {
                    styleArr += `paddingLeft: ${indent * 25}px; `;
                }
                if (lineHeight) {
                    styleArr += `lineHeight: ${lineHeight}; `;
                }
                const style = styleArr ? ` style="${styleArr}"` : "";
                return style ? `<div${style}>${elem}</div>` : elem;
            } else if (o.type === "img") {
                return `<image=\"${o.url}\">`;
            } else if (o.type === "latex") {
                return o.latex;
            } else if(o.type === 'video'){
                return `<video=\"${o.video}\">`;
            }
        })
        .join("\n");

    return returnObj;
};

const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

const cleanLatex = (text: string, delimiter = "$") => {
    const split = text.split(delimiter);
    let returnString = "";
    split.map((item, index) => {
        if (index % 2 === 0) {
            returnString += item;
        } else {
            returnString += replaceAll(
                `${delimiter}${item}${delimiter}`,
                "\n",
                " ",
            );
        }
    });
    return returnString;
};

const getParts = (text: any, displayType: string) => {
    const parts: { type: string; val: any }[] = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = cleanLatex(txt);
    txt = cleanLatex(txt, "$$");
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    const splitted = txt.split("\n");

    for (let splt of splitted) {
        splt = splt.trim();
        if (splt.includes("<image")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<image=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                displayType === "card" &&
                    prts.push({
                        type: "img",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else if(splt.includes("<video")){
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<video=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                prts.push({
                        type: "video",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        }else {
            const splitArr = splt.split("");
            const isLatex =
                splitArr[0] === "$" && splitArr[splitArr.length - 1] === "$";

            if (isLatex) {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            } else {
                if (!splt)
                    parts.push({
                        type: "break",
                        val: splt,
                    });
                else
                    parts.push({
                        type: "latex_div",
                        val: splt,
                    });
            }
        }
    }
    return parts;
};

export const renderer = (json: any, props = {}) => {
    const text = parseCMSJson(json);

    return (
        text &&
        getParts(text, "card").map((part, pIndex) => (
            <RenderPart part={part} key={"_" + pIndex} {...props}  />
        ))
    );
};

const DASH_DEVICE_WIDTH = 412;
const DASH_DEVICE_HEIGHT = 732;
export const getPercentTopLeft = (position: {
    top: number;
    left: number;
}): {
    top: string;
    left: string;
} => {
    const { top = 0, left = 0 } = position;
    const retPosition = {
        top: `${(top / DASH_DEVICE_HEIGHT) * 100}%`,
        left: `${(left / DASH_DEVICE_WIDTH) * 100}%`,
    };

    return {
        ...position,
        ...retPosition,
    };
};

export const getPercentHeightWidth = (size: {
    height: number;
    width: number;
}): {
    height: string;
    width: string;
} => {
    const { height = 0, width = 0 } = size;

    const scaleFactor = Math.max(width, height) / Math.min(width, height);
    const newWidth = (width / DASH_DEVICE_WIDTH) * 100;
    const newHeight = (height / DASH_DEVICE_WIDTH) * 100 * scaleFactor;
    const retSize = {
        height: `${newHeight}vw`,
        width: `${newWidth}vw`,
    };

    return {
        ...size,
        ...retSize,
    };
};
