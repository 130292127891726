import { MetaHead } from "@modules/shared/components";
import "@styles/katex.min.css";
import "@styles/global.scss";
import "@styles/style.css";
import { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "tailwindcss/tailwind.css";
import "react-spring-bottom-sheet/dist/style.css";
import dynamic from "next/dynamic";
import { Workbox } from "workbox-window";
// import microsoftClarity from "react-microsoft-clarity";
// import useSound from "use-sound";
// import { AppWrapper } from "@helpers/context"; // import based on where you put it
// import { RouteGuard } from "@modules/shared/components/RouteGuard";
// import { ErrorComponent } from "@modules/shared/components/ErrorComponent";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";
// import { isMobile } from "@utils/helpers";
// import { DndPreview } from "@modules/flow/components/ComparisonBlock";
// import SupportGuard from "@modules/shared/components/SupportGurad";
// import InternetGurad from "@modules/shared/components/InternetGurad";
// import SplashScreen from "@modules/shared/components/SplashScreen";
// import { GameplaySkeleton } from "@modules/shared/components/GameplaySkeleton";

const AppWrapper = dynamic(
    () => import("@helpers/context").then((mod) => mod.AppWrapper),
    {
        ssr: false,
    },
);

const UseGlobalGameplayWrapper = dynamic(
    () =>
        import("@modules/home/view/activity/Components/Progression/State").then(
            (mod) => mod.UseGlobalGameplayWrapper,
        ),
    {
        ssr: false,
    },
);

const RouteGuard = dynamic(
    () =>
        import("@modules/shared/components/RouteGuard").then(
            (mod) => mod.RouteGuard,
        ),
    {
        ssr: false,
    },
);

const SupportGuard = dynamic(
    () => import("@modules/shared/components/SupportGurad"),
    {
        ssr: false,
    },
);

const InternetGurad = dynamic(
    () => import("@modules/shared/components/InternetGurad"),
    {
        ssr: false,
    },
);

const SplashScreen = dynamic(
    () => import("@modules/shared/components/SplashScreen"),
    {
        ssr: false,
    },
);

const GameplaySkeleton = dynamic(
    () =>
        import("@modules/shared/components/GameplaySkeleton").then(
            (module) => module.GameplaySkeleton,
        ),
    {
        ssr: false,
    },
);

const ApolloProviderApp = dynamic(
    () => import("@modules/shared/components/ApolloProviderApp"),
    {
        ssr: false,
    },
);

const FramerLazyMotion = dynamic(
    () => import("@modules/shared/components/FramerLazyMotion"),
    {
        ssr: false,
    },
);

const blessCommonVideoComponent = () => {
    const video = document.getElementById("commonVideoComponent");
    video?.play();
};

const blessCommonAudioComponent = () => {
    const audio = document.getElementById("commonSoundComponent");
    audio?.play();
    audio?.pause();
};

function SetsGamify({ Component, pageProps }: AppProps): JSX.Element {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const workbox: any = useRef(null);
    useEffect(() => storePathValues, [router.asPath]);
    function storePathValues() {
        const storage = window.sessionStorage;
        if (!storage) return;
        // Set the previous path as the value of the current path.
        const prevPath = storage.getItem("currentPath") ?? "";
        storage.setItem("prevPath", prevPath);
        // Set the current path value by looking at the browser's location object.
        storage.setItem("currentPath", window.location.pathname);
    }

    useEffect(() => {
        Router.events.on("routeChangeStart", () => {
            setIsLoading(true);
        });
        Router.events.on("routeChangeComplete", () => {
            setIsLoading(false);
        });
        Router.events.on("routeChangeError", () => {
            setIsLoading(false);
        });
    }, [Router]);

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            const wb = new Workbox("/sw.js");
            wb.register().then(
                function (registration: any) {
                    console.log(
                        "Service Worker registration successful with scope: ",
                        registration.scope,
                    );
                },
                function (err: any) {
                    console.log("Service Worker registration failed: ", err);
                },
            );
            workbox.current = wb;
        }
        // setIsMounted(true);

        import("react-microsoft-clarity").then((microsoftClarity) => {
            microsoftClarity.clarity.init("hkn91a6niw");
        });
        // document.addEventListener("DOMContentLoaded", function () {
        //     removeControls();
        // });
        // window.onload = removeControls;

        // Bless common video and sound element

        document.addEventListener(
            "click",
            function () {
                blessCommonVideoComponent();
                blessCommonAudioComponent();
            },
            {
                // This will invoke the event once and de-register it afterward
                once: true,
            },
        );
    }, []);

    const { metadataTags } = pageProps;

    const addAssetsToSW = async (assets: string[], isDelete = false) => {
        if (workbox?.current) {
            workbox.current.messageSW({
                type: "PRECACHE",
                payload: assets,
                isDelete,
            });
        }
    };

    const addToCacheWithMessage = async (data: any) => {
        if (workbox?.current) {
            workbox.current.messageSW(data);
        }
    };

    // function removeControls() {
    //     const videoElements = document.querySelectorAll("video");
    //     videoElements.forEach((video) => {
    //         video.removeAttribute("controls");
    //     });
    // }

    // if (showSplash)
    //     return (
    //         <SplashScreen
    //             setShowSplash={setShowSplash}
    //             metadataTags={metadataTags}
    //         />
    //     );
    return (
        <>
            {showSplash && (
                <SplashScreen
                    showSplash={showSplash}
                    setShowSplash={setShowSplash}
                    metadataTags={metadataTags}
                />
            )}
            <MetaHead metadataTags={metadataTags} />
            {isLoading && <GameplaySkeleton />}
            <ApolloProviderApp>
                {/* Moved to specfic component where it is used to reduce bundle size */}
                {/* <DndProvider
                    backend={is_mobile ? TouchBackend : HTML5Backend}
                    {...(is_mobile ? { options: { delay: 200 } } : {})}
                > */}
                {/* <DndPreview /> */}
                <AppWrapper>
                    <UseGlobalGameplayWrapper>
                        {/* <ErrorBoundary> */}
                        <RouteGuard>
                            <InternetGurad>
                                {/* <SupportGuard> */}
                                <FramerLazyMotion>
                                    <Component
                                        {...pageProps}
                                        showSplash={showSplash}
                                        addAssetsToSW={addAssetsToSW}
                                        addToCacheWithMessage={
                                            addToCacheWithMessage
                                        }
                                    />
                                </FramerLazyMotion>
                                {/* </SupportGuard> */}
                            </InternetGurad>
                        </RouteGuard>
                        {/* </ErrorBoundary> */}
                    </UseGlobalGameplayWrapper>
                </AppWrapper>
                {/* </DndProvider> */}
            </ApolloProviderApp>
        </>
    );
}

export default SetsGamify;
