/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, ReactComponentElement, useMemo, useState } from "react";
import { renderer } from "../../../helpers/renderer";
import ReactDOMServer from "react-dom/server";

interface HighlightWrapperProps {
    children: ReactComponentElement<any>;
}
type Position = "top" | "bottom" | "left" | "right";

type HighlightFeedbackMessageContainer = {
    // distance: number;
    width: number;
    height: number;
    position?: Position;
    backgroundColor?: string;
};

type HighlightFeedbackMessage = {
    text: any;
    container: HighlightFeedbackMessageContainer;
};

type HighlightFeedbackArrow = {
    length: number;
    type: string;
    reverse?: boolean;
    distance?: {
        start?: number;
        end?: number;
    };
};

export type HighlightFeedbackElement = {
    id: string;
    message?: HighlightFeedbackMessage;
    arrow: HighlightFeedbackArrow;
};

export type SetHighlightType = (
    ovelayEnabled: boolean,
    highlightElements?: HighlightFeedbackElement[] | null,
    customFunc?: null | (() => void),
) => void;

export const setHighlightOnElement = (element: HighlightFeedbackElement) => {
    const elem = document.getElementById(element.id);
    if (!elem) return;
    elem.classList.add("highlight");
    const rect = elem.getBoundingClientRect();
    const { left: x, top: y, width: w, height: h } = rect;

    const highlight = document.createElement("div");
    highlight.id = `highlight/${element.id}`;
    highlight.classList.add("highlight");

    highlight.style.width = `${w}px`;
    highlight.style.height = `${h}px`;
    highlight.style.top = `${y}px`;
    highlight.style.left = `${x}px`;
    highlight.style.position = "fixed";
    document.body.appendChild(highlight);
};

const HighlightWrapper: FC<HighlightWrapperProps> = ({ children }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const getFeedbackPosition = (
        elementHeight: number,
        elementWidth: number,
        elementX: number,
        elementY: number,
        feedbackWidth: number,
        feedbackHeight: number,
        gap: number,
        position: Position = "top",
    ) => {
        const pos = {
            ["top"]: {
                top: elementY - gap - feedbackHeight,
                left: elementX + elementWidth / 2 - feedbackWidth / 2,
            },
            ["bottom"]: {
                top: elementY + gap + elementHeight,
                left: elementX + elementWidth / 2 - feedbackWidth / 2,
            },
            ["left"]: {
                left: elementX - gap - feedbackWidth,
                top: elementY + elementHeight / 2 - feedbackHeight / 2,
            },
            ["right"]: {
                left: elementWidth + elementX + gap,
                top: elementY + elementHeight / 2 - feedbackHeight / 2,
            },
        };
        return pos[position];
    };

    const getArrowPosition = (
        elementHeight: number,
        elementWidth: number,
        elementX: number,
        elementY: number,
        arrowLength: number,
        // gap: number,
        arrowDistance: {
            start: number;
            end: number;
        },
        position: Position = "top",
        reverse = false,
    ) => {
        const reverseAngle = reverse ? 180 : 0;

        const gap = reverse ? arrowDistance.start : arrowDistance.end;

        const angle = {
            ["top"]: {
                rotate: `${-90 + reverseAngle}deg`,
                top: elementY - gap - arrowLength,
                left: elementX + elementWidth / 2 - arrowLength / 2,
            },
            ["bottom"]: {
                rotate: `${90 + reverseAngle}deg`,
                top: elementY + elementHeight - 12 + arrowLength + gap,
                left: elementX + elementWidth / 2 - arrowLength / 2,
            },
            ["left"]: {
                rotate: `${180 + reverseAngle}deg`,
                top: elementY + elementHeight / 2,
                left: elementX - gap - arrowLength - 10,
            },
            ["right"]: {
                rotate: `${reverseAngle}deg`,
                top: elementY + elementHeight / 2,
                left: elementX + elementWidth + gap + (reverse ? 0 : 10),
            },
        };
        return angle[position];
    };

    function setFeedbackOnElement(element: HighlightFeedbackElement) {
        if (!element.message) return;
        const elem = document.getElementById(element.id)!;

        const rect = elem.getBoundingClientRect();
        const x = rect.left;
        const y = rect.top;
        const w = rect.width;
        const h = rect.height;

        const feedback = document.createElement("span");
        feedback.classList.add("feedback");

        const textDiv = document.createElement("div");

        const html = ReactDOMServer.renderToStaticMarkup(
            renderer(element.message.text) as any,
        );
        feedback.innerHTML = html.toString();
        feedback.appendChild(textDiv);
        elem.appendChild(feedback);

        feedback.style.padding = "4px";
        feedback.style.width = `${element.message.container.width}px`;
        feedback.style.height = `${element.message.container.height}px`;
        feedback.style.backgroundColor =
            element.message.container.backgroundColor ?? "#FFFFFF";
        const feedbackRect = feedback.getBoundingClientRect();

        const feedbackWidth = feedbackRect.width;
        const feedbackHeight = feedbackRect.height;

        const feedbackGap =
            element.arrow.length +
            (element.arrow.distance?.end ?? 0) +
            (element.arrow.distance?.start ?? 0);

        const position = getFeedbackPosition(
            h,
            w,
            x,
            y,
            feedbackWidth,
            feedbackHeight,
            feedbackGap,
            element.message.container.position,
        );

        feedback.style.top = `${position.top}px`;
        feedback.style.left = `${Math.max(position.left, 0)}px`;
        feedback.style.overflow = "hidden";

        if (position.left + feedbackWidth > window.innerWidth) {
            feedback.style.left = `${window.innerWidth - feedbackWidth}px`;
        }

        const arrow = document.createElement("div");
        arrow.style.width = `${element.arrow.length - 10}px`;
        const arrowPosition = getArrowPosition(
            h,
            w,
            x,
            y,
            element.arrow.length - (element.arrow.reverse ? 18 : 10),
            {
                start: element.arrow.distance?.start ?? 0,
                end: element.arrow.distance?.end ?? 0,
            },
            element.message.container.position,
            element.arrow.reverse,
        );
        arrow.classList.add("arrow-line");
        arrow.style.rotate = arrowPosition.rotate;
        arrow.style.top = `${arrowPosition.top}px`;
        arrow.style.left = `${arrowPosition.left}px`;

        elem.appendChild(arrow);
    }

    const resetHighlight = () => {
        document.querySelectorAll(".highlight").forEach((elem) => {
            elem.classList.remove("highlight");
        });
        document.querySelectorAll(".feedback").forEach((elem) => {
            elem.remove();
        });
        document.querySelectorAll(".arrow-line").forEach((elem) => {
            elem.remove();
        });
        setShowOverlay(false);
    };

    function setHighlight(
        ovelayEnabled: boolean,
        highlightElements: HighlightFeedbackElement[] | null = null,
        customFunc: null | (() => void) = null,
    ) {
        resetHighlight();
        if (customFunc) {
            setShowOverlay(false);
            customFunc();
            return;
        }
        setShowOverlay(ovelayEnabled);
        highlightElements?.forEach((element) => {
            if (ovelayEnabled) {
                setHighlightOnElement(element);
            }
            setFeedbackOnElement(element);
        });
    }

    const wrappedChildren = useMemo(
        () =>
            React.Children.map(children, (child) =>
                React.cloneElement(child, { setHighlight: setHighlight }),
            ),
        [children],
    );
    return (
        <>
            {showOverlay && <div className="overlay" />}
            <div style={{ width: "100%", height: "100%" }}>
                {wrappedChildren}
            </div>
        </>
    );
};

export default HighlightWrapper;
