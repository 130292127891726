interface collectionCardBgColorsType {
    bg: string;
    ac: string;
}

export const collectionCardBgColors: collectionCardBgColorsType[] = [
    { bg: "bg-mint-cream", ac: "bg-eucalyptus" },
    { bg: "bg-corn-silk", ac: "bg-gargoyle-gas" },
    { bg: "bg-azureish-white", ac: "bg-light-cobalt-blue" },
    { bg: "bg-lavender-blush", ac: "bg-congo-pink" },
];

export const questionText =
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?';

export const questionOptions = [
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
    '<image="https://s3.ap-south-1.amazonaws.com/homeworkcontent/NCERT_6th_Maths%2Fzzf.JPG">\nHow many of the figures given above have parallel lines ?',
];

export const classNameOptions = [
    { label: "Select your grade", value: 0 },
    // { label: "12th", value: 12 },
    // { label: "11th", value: 11 },
    // { label: "10th", value: 10 },
    // { label: "9th", value: 9 },
    // { label: "8th", value: 8 },
    // { label: "7th", value: 7 },
    { label: "6th", value: 6 },
    // { label: "5th", value: 5 },
    // { label: "4th", value: 4 },
    // { label: "3rd", value: 3 },
    // { label: "2nd", value: 2 },
    // { label: "1st", value: 1 },
];

export const profileTypeOptions = [
    { label: "", value: "" },
    { label: "Principal", value: "principal" },
    { label: "Teacher", value: "teacher" },
    { label: "Owner", value: "owner" },
    { label: "Government official", value: "government_official" },
    { label: "Vice Principal", value: "vice_principal" },
    { label: "School Coordinator", value: "school_coordinator" },
    { label: "Parent", value: "parent" },
    { label: "Other", value: "other" },
];

export const gradeRangeOptions = [
    { label: "", value: "" },
    { label: "Above 85", value: "86" },
    { label: "60 - 85", value: "70" },
    { label: "below 60", value: "59" },
];

export const languageOptions = [
    { label: "", value: "" },
    { label: "English + Hindi", value: "english_hindi" },
];
